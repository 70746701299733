/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetTypesCreateRequest } from '../models/AssetTypesCreateRequest';
import type { AssetTypesCreateResponse } from '../models/AssetTypesCreateResponse';
import type { AssetTypesDeleteResponse } from '../models/AssetTypesDeleteResponse';
import type { AssetTypesDetailsResponse } from '../models/AssetTypesDetailsResponse';
import type { AssetTypesInheritanceResponse } from '../models/AssetTypesInheritanceResponse';
import type { AssetTypesListResponse } from '../models/AssetTypesListResponse';
import type { AssetTypesUpdateRequest } from '../models/AssetTypesUpdateRequest';
import type { AssetTypesUpdateResponse } from '../models/AssetTypesUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetTypesService {

    /**
     * @param requestBody 
     * @returns AssetTypesCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Assettypes(
requestBody?: AssetTypesCreateRequest,
): CancelablePromise<AssetTypesCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assettypes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns AssetTypesListResponse Success
     * @throws ApiError
     */
    public static getApiV1Assettypes(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<AssetTypesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assettypes',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param id 
     * @returns AssetTypesDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1Assettypes1(
id: number,
): CancelablePromise<AssetTypesDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assettypes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns AssetTypesUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Assettypes(
id: number,
requestBody?: AssetTypesUpdateRequest,
): CancelablePromise<AssetTypesUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assettypes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns AssetTypesDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Assettypes(
id: number,
): CancelablePromise<AssetTypesDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/assettypes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AssetTypesInheritanceResponse Success
     * @throws ApiError
     */
    public static getApiV1AssettypesSubtypes(
id: number,
): CancelablePromise<AssetTypesInheritanceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assettypes/{id}/subtypes',
            path: {
                'id': id,
            },
        });
    }

}
