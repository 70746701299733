<template>
  <Dialog
    v-if="dialogVisible"
    v-model:visible="dialogVisible"
    modal
    :draggable="false"
    :header="headerText"
    position="top"
    style="max-width: 50%; min-width: 50%; max-height: 80%"
  >
    <template #default>
      <div class="flex flex-column gap-5 w-full">
        <div class="flex flex-row w-full gap-2">
          <div class="flex flex-column w-6">
            <label>Location Name*</label>
            <InputText
              type="text"
              v-model="locationName"
              placeholder="Name of the location"
            />
          </div>
          <div class="flex flex-column w-6">
            <label>Street Adress</label>
            <InputText
              type="text"
              v-model="streetAdress"
              placeholder="Adress"
              @keyup.enter="onSave"
            />
          </div>
        </div>
        <div class="flex flex-row gap-2">
          <div class="flex flex-column w-6">
            <label>Postal Code</label>
            <InputText
              type="text"
              v-model="postalCode"
              placeholder="Postal Code"
            />
          </div>
          <div class="flex flex-column w-6">
            <label>City</label>
            <InputText type="text" v-model="city" placeholder="City" />
          </div>
        </div>
        <div class="flex flex-row gap-2">
          <div class="flex flex-column w-6">
            <label>Country</label>
            <InputText type="text" v-model="country" placeholder="Country" />
          </div>
          <div class="flex flex-column w-6">
            <label>Region</label>
            <InputText type="text" v-model="region" placeholder="region" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button
          class="p-button-text"
          label="Cancel"
          :onClick="
            () => {
              dialogVisible = false;
            }
          "
        />
        <Button
          class="p-button-primary"
          :disabled="!locationName"
          label="Save"
          :onClick="onSave"
        />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { ref, watch, type Ref } from "vue";
import {
  type LocationsCreateRequest,
  LocationsService,
  type LocationsUpdateRequest,
} from "@/apis/luna-api";

const dialogVisible = ref(false);
const toast = useToast();
const streetAdress = ref("");
const locationName = ref("");
const postalCode = ref("");
const city = ref("");
const region = ref("");
const country = ref("");
const headerText = ref("Create new Location");
const requiredfieldsfilled = ref(false);

const props = defineProps<{
  visible: boolean;
  onHide: (edited: boolean) => any;
  location?: any;
}>();

watch(
  () => props.visible,
  (nv: any) => {
    console.log("VISIBLE: ", nv);
    if (nv) {
      dialogVisible.value = nv;
    }

    if (nv && props.location && props.location.locationName) {
      headerText.value = "Edit Location";
      locationName.value = props.location.locationName;
      streetAdress.value = props.location.streetAdress;
      city.value = props.location.city;
      postalCode.value = props.location.postalCode;
      region.value = props.location.region;
      country.value = props.location.country;
    }
  },
);
watch(
  () => dialogVisible.value,
  (nv: any) => {
    if (nv != null && !nv) {
      console.log("TRIGGER");
      headerText.value = "Create new Location";
      locationName.value = "";
      streetAdress.value = "";
      city.value = "";
      country.value = "";
      postalCode.value = "";
      region.value = "";
      props.onHide(true);
    }
  },
);

const onSave = async () => {
  if (props.location && props.location.locationName) {
    console.log("LOCATION: ", props.location);
    console.log("LOCATION NAME: ", props.location.locationName);
    await dispatchEditLocationAsync({
      id: props.location.id,
      streetAdress: streetAdress.value,
      city: city.value,
      region: region.value,
      postalCode: postalCode.value,
      country: country.value,
      locationName: locationName.value,
    } as LocationsUpdateRequest);
    return;
  }

  await dispatchCreateLocationAsync({
    streetAdress: streetAdress.value,
    city: city.value,
    region: region.value,
    postalCode: postalCode.value,
    country: country.value,
    locationName: locationName.value,
  } as LocationsCreateRequest);
};

async function dispatchCreateLocationAsync(
  locationsCreateRequest: LocationsCreateRequest,
) {
  await LocationsService.postApiV1Locations(locationsCreateRequest);
  toast.add({
    severity: "success",
    summary: "Location created",
    detail: "Location created successfully",
    life: 3000,
  });
  dialogVisible.value = false;
}

async function dispatchEditLocationAsync(
  locationsUpdateRequest: LocationsUpdateRequest,
) {
  await LocationsService.putApiV1Locations(
    props.location.id,
    locationsUpdateRequest,
  );
  dialogVisible.value = false;
}
</script>
