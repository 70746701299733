<template>
  <Dialog
    v-if="dialogVisible"
    v-model:visible="dialogVisible"
    modal
    :draggable="false"
    :header="headerText"
    position="top"
    style="max-width: 50%; min-width: 50%; max-height: 80%"
  >
    <div>
      <OrganizationChart :value="data">
        <template #default="item">
          <span>{{ item.node.name }}</span>
        </template>
      </OrganizationChart>
    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { AssetTypesService } from "@/apis/luna-api";
import OrganizationChart from "primevue/organizationchart";
import type { OrganizationChartNode } from "primevue/organizationchart";

import Dialog from "primevue/dialog";
import { ref, watch } from "vue";

const dialogVisible = ref(false);
const data = ref();

const props = defineProps<{
  visible: boolean;
  onHide: (edited: boolean) => any;
  assetType: any;
}>();

const headerText = ref("Hierarchy View");

watch(
  () => props.visible,
  async (nv: any) => {
    if (props.assetType.id) await fetchDataAsync();
    if (nv) {
      dialogVisible.value = nv;
    }
  },
);
watch(
  () => dialogVisible.value,
  (nv: any) => {
    if (nv != null && !nv) {
      props.onHide(true);
    }
  },
);

async function fetchDataAsync() {
  let res = await AssetTypesService.getApiV1AssettypesSubtypes(
    props.assetType.id,
  );

  data.value = res.responseItem!;
  console.log(data.value);
}
</script>
