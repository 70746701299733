<template>
  <div class="flex flex-column">
    <div class="mt-3 grid">
      <Card class="col lg:col xl:col sm:col-12 m-3">
        <template #title>General Information</template>
        <template #content>
          <div>
            <div>
              <div class="container">
                <ConDetailsItem
                  title="Name"
                  icon="pi pi-info-circle"
                  :content="`${asset.assetName}`"
                />
                <ConDetailsItem
                  title="Location"
                  icon="pi pi-map-marker"
                  :content="`${asset.location.locationName}`"
                />
                <ConDetailsItem
                  title="Asset Type"
                  icon="pi pi-info-circle"
                  :content="`${asset.assetType.assetTypeName}`"
                />
                <div v-for="field in asset.assetValues" :key="field.fieldName">
                  <ConDetailsItem
                    :title="field.fieldName"
                    icon="pi pi-info-circle"
                    :content="field.value"
                  ></ConDetailsItem>
                </div>
                <Divider></Divider>
              </div>
              <Chip
                :key="tag.id"
                v-for="tag in asset.tags"
                :label="tag.name"
                style="margin-right: 0.2em"
              ></Chip>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from "primevue/card";
import { ConDetailsItem } from "@congatec/primevue-components-lib";
import { ref, onMounted, watch } from "vue";
import { AssetsService } from "@/apis/luna-api";
import Divider from "primevue/divider";
import Chip from "primevue/chip";

onMounted(async () => {
  fetchDataAsync();
});

var props = defineProps({
  id: Number,
});

watch(
  () => props.id,
  (nv) => {
    if (nv) {
      fetchDataAsync();
    }
  },
);

const asset = ref({
  assetName: "",
  tags: [
    {
      id: 0,
      name: "",
    },
  ],
  location: {
    locationName: "",
  },
  assetType: {
    assetTypeName: "",
  },
  assetValues: [
    {
      fieldName: "",
      value: "",
    },
  ],
});

async function fetchDataAsync() {
  console.log("ID: ", props.id);
  var res = await AssetsService.getApiV1Assets1(props.id!);
  asset.value.assetName = res.assetName ? res.assetName : "";
  asset.value.tags = res.tags
    ? res.tags.map((tag: any) => ({
        id: tag.id,
        name: tag.name,
      }))
    : [];
  asset.value.assetType = res.assetType
    ? {
        assetTypeName: res.assetType.name!,
      }
    : { assetTypeName: "" };
  asset.value.location = res.location
    ? {
        locationName: res.location.locationName!,
      }
    : { locationName: "" };
  asset.value.assetValues = res.assetValues
    ? res.assetValues.map((assetValue: any) => ({
        fieldName: assetValue.fieldName,
        value: assetValue.value,
      }))
    : [];
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
</style>
