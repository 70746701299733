import SuperBOMIO  from "../SuperBOMIO.vue"
import { to } from '@congatec/primevue-components-lib';

const title = "SuperBOMIO"

const superbomioRoutes = [
    {
      path: '/superbomio',
      name: 'SuperBOMIO',
      
      component: SuperBOMIO,
      meta: {
        breadcrumb: [{ title: title }, { label: "SuperBOMIO" }],
        
      },
    },
  ];

  export default superbomioRoutes;