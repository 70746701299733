<template>
    <div class="flex flex-column gap-3">
        <div class="flex flex-row gap-3 align-items-center">
            <div class="w-4">
                <label :for="'rulename'">Rule Name<span>*</span></label>
                <InputText :id="'rulename'" v-model="editRule.name" placeholder="Enter Rule Name" class="w-full"
                    :class="{ 'input-invalid': v$.name.$invalid && v$.name.$error, 'h-3rem': true }" />
                <span v-if="v$.name.$error">{{ v$.name.$errors[0].$message }}</span>
            </div>
            <div class="w-auto flex flex-column">
                <label for="ruletype">Rule Type </label>
                <Dropdown id="ruletype" v-model="editRule.type" :options="Object.values(ReleaseRuleType)"
                    placeholder="Select Rule Type" class="w-full md:w-14rem" @change="ruleTypeChanged" />
            </div>
            <div class="w-auto">
                <label>Optional </label>
                <Checkbox v-model="editRule.optional" :binary="true" />
            </div>
        </div>
        <div v-if="editRule.type == ReleaseRuleType.DEPARTMENT && editRule.department" class="flex flex-row gap-3">
            <div class="w-6">
                <label :for="'departmentname'">Department Name<span>*</span></label>
                <InputText :id="'departmentname'" v-model="editRule.department.departmentName"
                    placeholder="Enter the Department Name" class="w-full"
                    :class="{ 'input-invalid': v$.department.departmentName.$invalid && v$.department.departmentName.$error, 'h-3rem': true }" />
                <span v-if="v$.department.departmentName.$error">{{ v$.department.departmentName.$errors[0].$message
                }}</span>
            </div>
            <div class="w-auto">
                <label :for="'approvals'">Number of Approvals<span>*</span></label>
                <InputNumber :id="'approvals'" showButtons v-model="editRule.department.numberOfApprovals"
                    placeholder="Enter the Department Name" class="w-full"
                    :class="{ 'input-invalid': v$.department.numberOfApprovals.$invalid && v$.department.numberOfApprovals.$error, 'h-3rem': true }" />
                <span v-if="v$.department.numberOfApprovals.$error">{{ v$.department.numberOfApprovals.$errors[0].$message
                }}</span>
            </div>
        </div>
        <div v-else-if="editRule.type == ReleaseRuleType.SPECIFIC_PERSON && editRule.person" class="flex flex-column gap-3">
            <div class="w-full">
                <label :for="'person'">Person<span>*</span></label>
                <AutoComplete :id="'person'" class="w-full" placeholder="Enter name or email" @change="personChange"
                    v-model="editRule.person.email" optionLabel="identifier" :suggestions="usersFound"
                    @item-select="selectUser"
                    :class="{ 'input-invalid': v$.person.$invalid && v$.person.$error, 'h-3rem': true }" />
                <span v-if="v$.person.subjectId.$error">{{ v$.person.subjectId.$errors[0].$message }}</span>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import {
    ReleaseRuleType,
    UserService} from "@/apis/aready-api";
import AutoComplete from 'primevue/autocomplete';
import type { AutoCompleteChangeEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete';
import InputNumber from 'primevue/inputnumber';
import { useDelayTimer, delay } from '@congatec/primevue-components-lib';
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import InputText from 'primevue/inputtext';
import Dropdown, { type DropdownChangeEvent } from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();

// const props = defineProps<{
//     rule: ReleaseRuleTableListRule,
// }>();
// const editRule = ref({
//     name: props.rule.name,
//     optional: props.rule.optional,
//     type: props.rule.type,
//     person: props.rule.person,
//     department: props.rule.department,
// })

const editRule = defineModel<any, string>();

const vuelidaterules = {
    name: { required },
    person: {
        subjectId: { required: requiredIf(() => editRule.value.type == ReleaseRuleType.SPECIFIC_PERSON) },
    },
    department: {
        departmentName: { required: requiredIf(() => editRule.value.type == ReleaseRuleType.DEPARTMENT) },
        numberOfApprovals: { required: requiredIf(() => editRule.value.type == ReleaseRuleType.DEPARTMENT) },
    },
}

const v$ = useVuelidate(vuelidaterules, editRule);

const personChange = (event: AutoCompleteChangeEvent) => {
    delay(userQueryTimer, async () => {
        if (!editRule.value) {
            usersFound.value = [];
            return;
        }

        let query = event.value;
        // if (editRule.value.person?.firstName) {
        //     query = editRule.value.person?.firstName!;
        // } else if (editRule.value.person?.lastName) {
        //     query = editRule.value.person?.lastName!;
        // } else if (editRule.value.person?.email) {
        //     query = editRule.value.person?.email!;
        // }
        if (query) {

            usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

            for (let user of usersFound.value) {
                if (user.email) {
                    user.identifier = user.email;
                } else {
                    user.identifier = `${user.firstName} ${user.lastName}`
                }
            }
        }
        else {
            editRule.value.person!.firstName = null;
            editRule.value.person!.lastName = null;
            editRule.value.person!.email = null;
            editRule.value.person.subjectId = null;
        }
    });

};

const selectUser = (event: AutoCompleteItemSelectEvent) => {
    console.log("Item:", event)
    if (!editRule.value.person) {
        return;
    }
    editRule.value.person!.firstName = event.value.firstName;
    editRule.value.person!.lastName = event.value.lastName;
    editRule.value.person!.email = event.value.email;
    editRule.value.person.subjectId = event.value.subjectId;
    console.log("Person: ", editRule.value.person);
}

const ruleTypeChanged = (event: DropdownChangeEvent) => {
    console.log(event.value);
    if (event.value == ReleaseRuleType.SPECIFIC_PERSON && editRule.value.person == null) {
        editRule.value.person = { firstName: '', lastName: '', email: '', department: '' };
    }
    else if (event.value == ReleaseRuleType.DEPARTMENT && editRule.value.department == null) {
        editRule.value.department = { departmentName: '', numberOfApprovals: 1 };
    }
}
</script>
<style>
.input-invalid {
    border: 1px solid var(--primary-color);
}

.input-invalid:hover {
    border: 1px solid var(--primary-color) !important;
}
</style>
<style scoped>
/* It is totally fine to have two style blocks in one component and it works!
https://vuejs.org/api/sfc-css-features.html#mixing-local-and-global-styles */
span {
    color: var(--primary-color);
    font-weight: 600;
}
</style>