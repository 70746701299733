<template>
  <Dialog
    v-if="dialogVisible"
    v-model:visible="dialogVisible"
    modal
    :draggable="false"
    :header="headerText"
    position="top"
    style="max-width: 50%; min-width: 50%; max-height: 80%"
  >
    <template #default>
      <div class="flex flex-column gap-5 w-full">
        <div class="flex flex-row w-full gap-2">
          <div class="flex flex-column w-6">
            <label>Asset Name</label>
            <InputText
              type="text"
              v-model="assetName"
              placeholder="Name of the Asset"
              @keyup.enter="onSave"
            />
          </div>

          <div class="flex flex-column w-6">
            <label>Asset Type</label>
            <Dropdown
              v-model="assetType"
              placeholder="Type of asset"
              @keyup.enter="onSave"
              :options="assetTypes"
              optionLabel="name"
              @change="onAssetTypeChanged"
            />
          </div>
        </div>
        <div class="flex flex-row gap-2">
          <div class="flex flex-column w-6">
            <label>Location</label>
            <Dropdown
              v-model="location"
              placeholder="Location of asset"
              @keyup.enter="onSave"
              :options="locations"
              optionLabel="locationName"
            />
          </div>
          <div class="flex flex-column w-6">
            <label>Tags</label>
            <MultiSelect
              v-model="tags"
              placeholder="Tags for the Asset"
              filter
              display="chip"
              :options="tagoptions"
              optionLabel="name"
            />
          </div>
        </div>
        <div
          v-for="(field) in fields"
          :key="field.fieldId"
          class="flex flex-row w-full gap-2"
        >
          <div
            class="flex flex-column w-6"
            v-if="field.fieldType === FieldType.TEXT"
          >
            <label>{{ field.name }}</label>
            <InputText
              type="text"
              v-model="valuesInput[valuesInput.findIndex((x) => x.fieldId === field.fieldId)].value"
              :placeholder="field.name!"
              @keyup.enter="onSave"
            />
          </div>
          <div
            class="flex flex-column w-6"
            v-if="field.fieldType === FieldType.NUMERIC"
          >
            <label>{{ field.name }}</label>
            <InputNumber
              v-model="valuesInput[valuesInput.findIndex((x) => x.fieldId === field.fieldId)].value"
              :placeholder="field.name!"
              @keyup.enter="onSave"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button
          class="p-button-text"
          label="Cancel"
          :onClick="
            () => {
              dialogVisible = false;
            }
          "
        />
        <Button class="p-button-primary" label="Save" :onClick="onSave" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import MultiSelect from "primevue/multiselect";
import { ref, watch, type Ref } from "vue";
import {
  type AssetsCreateRequest,
  AssetsService,
  LocationsService,
  TagsService,
  AssetTypesService,
  type AssetsUpdateRequest,
  type AssetsDetailsResponseAssetTypeFields,
  type AssetsDetailsResponseAssetValues,
  type SetValueRequest,
  FieldType,
  FieldsService,
} from "@/apis/luna-api";
import InputNumber from "primevue/inputnumber";

const dialogVisible = ref(false);
const toast = useToast();
const assetName = ref("");
const tags: Ref<Array<any>> = ref([]);
const tagoptions: Ref<Array<any>> = ref([]);
const location: Ref<any> = ref(null);
const locations: Ref<Array<any>> = ref([]);
const assetType: Ref<any> = ref(null);
const assetTypes: Ref<Array<any>> = ref([]);
const headerText = ref("Create new Asset");
const fields: Ref<Array<AssetsDetailsResponseAssetTypeFields>> = ref([]);
const values: Ref<Array<AssetsDetailsResponseAssetValues>> = ref([]);
const valuesInput: Ref<Array<any>> = ref([]);

const props = defineProps<{
  visible: boolean;
  onHide: (edited: boolean) => any;
  asset?: any;
}>();

watch(
  () => props.visible,
  async (nv: any) => {
    console.log("VISIBLE: ", nv);
    await fetchDataAsync();
    if (nv) {
      dialogVisible.value = nv;
    }

    if (nv && props.asset && props.asset.assetName) {
      console.log("ASSET: ", props.asset);
      console.log("TAGS: ", props.asset.tags);
      headerText.value = "Edit Asset";
      assetName.value = props.asset.assetName;
      tags.value = mapTags();
      location.value = mapLocation();
      assetType.value = mapAssetType();
      fields.value = await mapFields();
      console.log("LOC: ", location.value);
      console.log("TYPE: ", assetType.value);
    }
  },
);
watch(
  () => dialogVisible.value,
  (nv: any) => {
    if (nv != null && !nv) {
      console.log("TRIGGER");
      headerText.value = "Create new Asset";
      assetName.value = "";
      tags.value = [];
      assetType.value = null;
      location.value = null;
      fields.value = [];
      values.value = [];
      valuesInput.value = [];
      props.onHide(true);
    }
  },
);

const onSave = async () => {
  
  if (props.asset && props.asset.assetName) {
    await dispatchEditAssetAsync({
      id: props.asset.id,
      assetName: assetName.value,
      tagIds: tags.value.map((el) => el.id),
      locationId: location.value.id,
      assetTypeId: assetType.value.id,
    } as AssetsUpdateRequest);

    await saveAssetValues(props.asset.id);
    
    return;
  }

  let res = await dispatchCreateAssetAsync({
    assetName: assetName.value,
    tagIds: tags.value.map((el) => el.id),
    locationId: location.value?.id,
    assetTypeId: assetType.value?.id,
  } as AssetsCreateRequest);

  await saveAssetValues(res!);
};

const onAssetTypeChanged = async (event: any) => {
  var res = await AssetTypesService.getApiV1Assettypes1(event.value.id);
  fields.value = []; 
  res.baseAssetTypes!.forEach((x) => { fields.value = fields.value.concat(x.fields!.map(detail => ({ name: detail.name, fieldType: detail.fieldType, fieldId: detail.id } as AssetsDetailsResponseAssetTypeFields))); });
  fields.value = fields.value.concat(res.fields!.map(detail => ({ name: detail.name, fieldType: detail.fieldType, fieldId: detail.id } as AssetsDetailsResponseAssetTypeFields)));
  
  // remove values
  for(var i = 0; i < valuesInput.value.length; i++) {
  // valuesInput.value.forEach((removeItem, index) => {
    if(fields.value.findIndex((x) => x.fieldId === valuesInput.value[i].fieldId) === -1) {
      valuesInput.value.splice(i, 1);
      i--;
    }
  }//)

  fields.value.forEach((field) => {
    if(valuesInput.value.findIndex((x) => x.fieldId === field.fieldId) === -1) {
      valuesInput.value.push({
        value: "",
        fieldId: field.fieldId!,
      });
    }
  });
  //fields.value.sort(function(a: AssetsDetailsResponseAssetTypeFields,b: AssetsDetailsResponseAssetTypeFields){return a.fieldId! - b.fieldId!})
}

async function dispatchCreateAssetAsync(
  assetsCreateRequest: AssetsCreateRequest,
) {
  let res = await AssetsService.postApiV1Assets(assetsCreateRequest);
  toast.add({
    severity: "success",
    summary: "Asset created",
    detail: "Asset created successfully",
    life: 3000,
  });


  return res.id;
}

async function fetchDataAsync() {
  let res = await LocationsService.getApiV1Locations();
  locations.value = res.locations!;
  let assetRes = await AssetTypesService.getApiV1Assettypes();
  assetTypes.value = assetRes.assetTypes!;
  let tagRes = await TagsService.getApiV1Tags();
  tagoptions.value = tagRes.tags!;
}

async function dispatchEditAssetAsync(
  assetsUpdateRequest: AssetsUpdateRequest,
) {
  await AssetsService.putApiV1Assets(props.asset.id, assetsUpdateRequest);

}

function mapTags() {
  let res: Array<any> = [];
  console.log(tags);
  props.asset.tags.forEach((element: any) => {
    let opt = tagoptions.value.find((y) => y.id == element.id);
    if (opt) res.push(opt);
  });
  return res;
}

function mapLocation() {
  return locations.value.find((y) => y.id == props.asset.location.id);
}

function mapAssetType() {
  return assetTypes.value.find((y) => y.id == props.asset.type.id);
}

async function mapFields() {
  let res = await AssetsService.getApiV1Assets1(props.asset.id);
  values.value = res.assetValues ? res.assetValues : [];
  if (values.value) {
    values.value.forEach((value) => {
      valuesInput.value.push({
        value: value.value ? value.value : "",
        fieldId: value.fieldId ? value.fieldId : 0,
      });
    });
    // add fields that dont have a value yet
    res.assetType?.fields?.forEach((field) => {
    if(valuesInput.value.findIndex((x) => x.fieldId === field.fieldId) === -1) {
      valuesInput.value.push({
        value: "",
        fieldId: field.fieldId!,
      });
    }
  });
  }
  else {
    fields.value.forEach((field) => {
      valuesInput.value.push({
        value: "",
        fieldId: field.fieldId!,
      });
    });
  }
  valuesInput.value;
  return res.assetType?.fields ? res.assetType?.fields : [];
}

async function saveAssetValues(assetId: number) {
  console.log("SET VALUES CALLED WITH FIELDS: ", fields.value);
  console.log("SET VALUES CALLED WITH VALUES: ", valuesInput.value);
  for (let i = 0; i < fields.value.length; i++) {
    await FieldsService.postApiV1FieldsValues(fields.value[i].fieldId!, {
      assetId: assetId,
      fieldId: fields.value[i].fieldId,
      value: String(
        valuesInput.value.find((x) => x.fieldId == fields.value[i].fieldId)
          ?.value,
      ),
    });
    console.log("FieldIds: ", fields.value[i].fieldId);
    console.log(
      "FieldValues: ",
      valuesInput.value.find((x) => x.fieldId == fields.value[i].fieldId),
    );
  }
  dialogVisible.value = false;
}
</script>
