<template>
  <ConfirmDialog />
  <Toast position="center" group="center" />
  <AssetTypesCreate
    :visible="showCreateDialog"
    :onHide="onCreateClose"
    :assetType="selectedAssetType"
  >
  </AssetTypesCreate>
  <AssetTypeInheritance
    :visible="showInheritanceDialog"
    :onHide="onInheritanceClose"
    :assetType="selectedAssetType"
  >
  </AssetTypeInheritance>
  <Toolbar>
    <template #start>
      <Button
        label="Create Asset Type"
        icon="pi pi-plus-circle"
        @click="createAssetType()"
      />
    </template>
  </Toolbar>
  <div class="m-5 mt-0">
    <ConDataTable
      tableId="AssetTypeslist"
      :columns="assetTypeTableColumns"
      filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]"
      :onReload="fetchDataAsync"
      ref="assetTypeTableRef"
      :createActionMenu="createActionMenu"
      globalFilter="name|description"
    >
    </ConDataTable>
  </div>
</template>
<script setup lang="ts">
import { AssetTypesService } from "@/apis/luna-api/services/AssetTypesService";
import { ref } from "vue";
import { ConDataTable } from "@congatec/primevue-components-lib";
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import AssetTypesCreate from "./AssetTypesCreate.vue";
import { useToast } from "primevue/usetoast";
import router from "@/globalRouter";
import { id } from "date-fns/locale";
import AssetTypeInheritance from "./AssetTypeInheritance.vue";

const assetTypeTableRef = ref<null | typeof ConDataTable>(null);
const confirm = useConfirm();
const showCreateDialog = ref(false);
const showInheritanceDialog = ref(false);
const toast = useToast();
const selectedAssetType = ref({});

const assetTypeTableColumns = [
  {
    field: "id",
    header: "ID",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "name",
    header: "Asset Type Name",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "description",
    header: "Description",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "baseTypeName",
    header: "Base Asset Type Name",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "actionMenu",
  },
];

async function fetchDataAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await AssetTypesService.getApiV1Assettypes(
    filters,
    sorts,
    page,
    rows,
  );
  return {
    totalCount: res.totalCount,
    data: res.assetTypes,
  };
}

function createActionMenu(row: any): any {
  return [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        selectedAssetType.value = row;
        showCreateDialog.value = true;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => deleteAssetType(row),
    },
    {
      label: "Assettype Details",
      icon: "pi pi-eye",
      command: () =>
        router.push({
          path: "/luna/assettype-details/" + row.id,
        }),
    },
    {
      label: "View inheritance",
      icon: "pi pi-sitemap",
      command: () => {
        showInheritanceDialog.value = true;
        selectedAssetType.value = row;
      },
    },
  ];
}

function createAssetType() {
  showCreateDialog.value = true;
}

function deleteAssetType(row: any) {
  confirm.require({
    header: "Delete Asset Type",
    icon: "pi pi-question-circle",
    acceptClass: "p-button-info",
    message: "Are you sure you want to delete this Item?",
    accept: async () => {
      try {
        await AssetTypesService.deleteApiV1Assettypes(row.id);
        toast.add({
          severity: "success",
          summary: "Delete",
          detail: "Item deleted",
          life: 3000,
        });

        reloadTableData();
      } catch (ex: any) {
        toast.add({
          severity: "error",
          summary: "Error!",
          detail: "An error occured while deleting: " + ex.message,
          life: 3000,
        });
      }
    },
  });
}
const onCreateClose = () => {
  showCreateDialog.value = false;
  selectedAssetType.value = {};
  reloadTableData();
};

const onInheritanceClose = () => {
  showInheritanceDialog.value = false;
  selectedAssetType.value = {};
  reloadTableData();
};

function reloadTableData() {
  assetTypeTableRef.value?.forceReload();
}
</script>

<style scoped lang="scss">
.tool-bar {
  background: none;
}
</style>
