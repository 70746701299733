<template>
  <div>
    <ArtifactInputDialog :visible="showArtifactInputDialog!" :guid="artifactguid!" :description="artifactDescription!"
      :state="artifactState!" :plmItem="artifactPlmItem!" @close="() => {
        showArtifactInputDialog = false;
        reloadArtifactsTable();
      }
        " />

    <Dialog v-model:visible="infoVisible" modal header="Artifact">
      <ConDetailsItem v-if="selectedRow.description" title="Description" :content="selectedRow.description" />
      <ConDetailsItem title="File Hash" :content="selectedRow.fileHash" />
      <ConDetailsItem title="Hash Algorithm" :content="selectedRow.hashAlgorithm" />
    </Dialog>

    <div class="m-5 mt-7">
      <ConDataTable tableId="ArtifactList" :title="' '" :columns="artifactTableColumns" filterDisplay="menu"
        :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchArtifactDataAsync" :createActionMenu="artifactActionMenu"
        :expansionColumns="tableExpansionColumns" expansionKey="bundles" ref="artifactTableRef" globalFilter="GlobalSearch" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ReleaseStatus, ArtifactService } from "@/apis/aready-api";
import { ref } from "vue";
import { ConDataTable, ConDetailsItem } from "@congatec/primevue-components-lib";
import Toast from "primevue/toast";
import ArtifactInputDialog from "./ArtifactInputDialog.vue";
import { useRouter } from "vue-router";
import Dialog from 'primevue/dialog';

const router = useRouter();
const showArtifactInputDialog = ref(false);
const artifactguid = ref();
const artifactDescription = ref();
const artifactIdentifier = ref();
const artifactPlmItem = ref();
const artifactState = ref();
const artifactTableRef = ref<null | typeof ConDataTable>(null);
const infoVisible = ref(false);
const selectedRow = ref();

async function fetchArtifactDataAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await ArtifactService.getApiV1Artifacts(
    filters,
    sorts,
    page,
    rows,
  );
  console.log("Resolution: ", res);
  return {
    totalCount: res.totalCount,
    data: res.artifacts,
  };
}

function artifactActionMenu(row: any, options?: any): any {
  if (options?.type == 'expansion') {
    return [
      {
        label: 'Bundle Details',
        icon: 'pi pi-eye',
        command: () => {
          router.push({ name: 'BundlesDetails', params: { guid: row.guid } });
        }
      }
    ];
  }
  return [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        artifactguid.value = row.guid;
        artifactDescription.value = row.description;
        artifactIdentifier.value = row.identifier;
        artifactPlmItem.value = row.plmItem;
        artifactState.value = row.state;
        showArtifactInputDialog.value = true;
      },
    },
    {
      label: "Details",
      icon: "pi pi-eye",
      command: () => {
        infoVisible.value = true;
        selectedRow.value = row;
      },
    },
    {
      label: "Download",
      icon: "pi pi-download",
      command: () => {
        artifactDownload(row);
      },
    },
    {
      label: "Review",
      icon: "pi pi-file-check",
      command: () => goToReview(row.guid),
    },
  ];
}

function reloadArtifactsTable() {
  artifactTableRef.value?.forceReload();
}

const artifactTableColumns = [
  {
    expander: true,
    field: "expansion",
    header: "",
  },
  {
    field: "guid",
    header: "Identifier",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "filePath",
    header: "File Path",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "state",
    header: "State",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "plmItem",
    header: "Plm Item",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "fileSize",
    header: "File Size",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  },
];

const tableExpansionColumns = [
  {
    field: "guid",
    header: "Bundle Guid",
    dataType: "text",
  },
  {
    field: "state",
    header: "Bundle State",
    dataType: "text",
  },
  {
    field: "name",
    header: "Bundle Name",
    dataType: "text",
  },
  {
    field: "version",
    header: "Bundle Version",
    dataType: "text",
  },
  {
    field: "actionMenu",
  },
];

const artifactDownload = async (row: any) => {
  let result = await ArtifactService.getApiV1ArtifactsUrl(row.guid);
  window.open(result.objectUrl!);
}

const goToReview = (guid: string) => {
  router.push({ name: 'ReviewArtifact', params: { guid } });
}

</script>
<style></style>
