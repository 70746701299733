<template>
  <Dialog
    v-if="dialogVisible"
    v-model:visible="dialogVisible"
    modal
    :draggable="false"
    :header="headerText"
    position="top"
    style="max-width: 50%; min-width: 50%; max-height: 80%"
  >
    <template #default>
      <div class="flex flex-column gap-5 w-full">
        <div class="flex flex-row w-full gap-2">
          <div class="flex flex-column w-6">
            <label>Asset Type Name</label>
            <InputText
              type="text"
              v-model="assetTypeName"
              placeholder="Name of the Asset Type"
              @keyup.enter="onSave"
            />
          </div>
          <div class="flex flex-column w-6">
            <label>Description</label>
            <InputText
              type="text"
              v-model="description"
              placeholder="Description"
            />
          </div>
        </div>
        <div class="flex flex-row gap-2">
          <div class="flex flex-column w-6">
            <label>Base Asset Type</label>
            <Dropdown
              v-model="selectedBaseAssetType"
              placeholder="Base Asset Type"
              @keyup.enter="onSave"
              :options="baseAssetTypeOptions"
              optionLabel="name"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button
          class="p-button-text"
          label="Cancel"
          :onClick="
            () => {
              dialogVisible = false;
            }
          "
        />
        <Button class="p-button-primary" label="Save" :onClick="onSave" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import { type Ref, ref, watch } from "vue";
import {
  type AssetTypesCreateRequest,
  AssetTypesService,
  type AssetTypesUpdateRequest,
  type AssetTypesListResponseAssetType,
} from "@/apis/luna-api";

const dialogVisible = ref(false);
const toast = useToast();
const description = ref("");
const assetTypeName = ref("");
const headerText = ref("Create new Asset Type");
const baseAssetTypeOptions: Ref<Array<AssetTypesListResponseAssetType>> = ref(
  [],
);
const selectedBaseAssetType = ref();

const props = defineProps<{
  visible: boolean;
  onHide: (edited: boolean) => any;
  assetType?: any;
}>();

watch(
  () => props.visible,
  async (nv: any) => {
    await fetchDataAsync();
    if (nv) {
      dialogVisible.value = nv;
    }

    if (nv && props.assetType && props.assetType.name) {
      headerText.value = "Edit Asset Type";
      assetTypeName.value = props.assetType.name;
      description.value = props.assetType.description;
      selectedBaseAssetType.value = mapBaseAssetType();
    }
  },
);
watch(
  () => dialogVisible.value,
  (nv: any) => {
    if (nv != null && !nv) {
      headerText.value = "Create new Asset Type";
      assetTypeName.value = "";
      description.value = "";
      selectedBaseAssetType.value = null;
      props.onHide(true);
    }
  },
);

const onSave = async () => {
  if (props.assetType && props.assetType.name) {
    await dispatchEditAssetTypeAsync({
      id: props.assetType.id,
      description: description.value,
      name: assetTypeName.value,
      baseAssetTypeId: selectedBaseAssetType.value?.id,
    } as AssetTypesUpdateRequest);
    return;
  }

  await dispatchCreateAssetTypeAsync({
    description: description.value,
    name: assetTypeName.value,
    baseAssetTypeId: selectedBaseAssetType.value?.id,
  } as AssetTypesCreateRequest);
};

async function dispatchCreateAssetTypeAsync(
  assetTypesCreateRequest: AssetTypesCreateRequest,
) {
  await AssetTypesService.postApiV1Assettypes(assetTypesCreateRequest);
  toast.add({
    severity: "success",
    summary: "AssetType created",
    detail: "AssetType created successfully",
    life: 3000,
  });
  dialogVisible.value = false;
}

async function dispatchEditAssetTypeAsync(
  assetTypesUpdateRequest: AssetTypesUpdateRequest,
) {
  await AssetTypesService.putApiV1Assettypes(
    props.assetType.id,
    assetTypesUpdateRequest,
  );
  dialogVisible.value = false;
}

async function fetchDataAsync() {
  let res = await AssetTypesService.getApiV1Assettypes();
  baseAssetTypeOptions.value = res.assetTypes!;
}

function mapBaseAssetType() {
  return baseAssetTypeOptions.value.find(
    (y) => y.id == props.assetType.baseTypeId,
  );
}
</script>
