<template>
  <div class="grid mt-3 ml-3 mr-3">
    <div class="col-6 lg:col-6 xl:col-6 sm:col-12">
      <Card class="w-full h-full">
        <template #title>General Information</template>
        <template #content>
          <div>
            <div>
              <div class="container">
                <ConDetailsItem
                  title="Name"
                  icon="pi pi-info-circle"
                  :content="`${assettype.name}`"
                />
                <ConDetailsItem
                  title="Description"
                  icon="pi pi-home"
                  :content="`${assettype.description}`"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-6 lg:col-6 xl:col-6 sm:col-12">
      <Card class="field-container w-full h-full">
        <template #title>Fields</template>
        <template #content>
          <div v-for="basetype in assettype.baseAssetTypes" :key="basetype.id">
            <h4>Inherited Fields from {{ basetype.name }}</h4>
            <div
              v-for="(field, index) in basetype.fields"
              style="margin-bottom: 1rem; display: flex"
              :key="field.name!"
            >
              <InputText
                :id="'name' + index"
                type="text"
                v-model="field.name"
                style="margin-right: 1rem"
                disabled="true"
              />
              <Dropdown
                v-model="field.fieldType"
                :id="'type' + index"
                :options="fieldTypes"
                placeholder="Select a Fieldtype"
                class="w-full md:w-14rem"
                style="margin-right: 1rem"
                :disabled="true"
              />
            </div>
          </div>
          <Divider></Divider>
          <div>
            <h4>Fields of this Asset Type</h4>
            <div
              v-for="(field, index) in assettype.fields"
              style="margin-bottom: 1rem; display: flex"
              :key="field.name!"
            >
              <InputText
                :id="'name' + index"
                type="text"
                v-model="field.name"
                style="margin-right: 1rem"
                disabled="true"
              />
              <Dropdown
                v-model="field.fieldType"
                :id="'type' + index"
                :options="fieldTypes"
                placeholder="Select a Fieldtype"
                class="w-full md:w-14rem"
                style="margin-right: 1rem"
                :disabled="true"
              />
              <div class="flex flex-row gap-1">
                <Button
                  :id="'btnRemove' + index"
                  icon="pi pi-trash"
                  @click="removeIndex(field.id!)"
                  class="userbutton"
                  text
                />
              </div>
            </div>
            <div style="margin-bottom: 1rem; display: flex">
              <InputText
                :id="'new name'"
                type="text"
                v-model="newField.name"
                style="margin-right: 1rem"
                placeholder="Type in a name"
              />
              <Dropdown
                v-model="newField.fieldType"
                :id="'new type'"
                :options="fieldTypes"
                placeholder="Select a Fieldtype"
                class="w-full md:w-14rem"
                style="margin-right: 1rem"
              />
              <div class="flex flex-row gap-1">
                <Button
                  icon="pi pi-plus"
                  @click="
                    addIndex(props.id!, newField.fieldType, newField.name)
                  "
                  class="userbutton"
                  text
                  :disabled="!isEnumSet(newField.fieldType) || !newField.name"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from "primevue/card";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { ConDetailsItem } from "@congatec/primevue-components-lib";
import { ref, onMounted, watch, type Ref } from "vue";
import {
  AssetTypesService,
  FieldsService,
  FieldType,
  type AssetTypesDetailsResponse,
  type FieldsCreateRequest,
} from "@/apis/luna-api";
import Divider from "primevue/divider";

var fieldTypes = ref([FieldType.NUMERIC, FieldType.TEXT]);
const assettype: Ref<AssetTypesDetailsResponse> = ref({});
const newField = ref({
  name: "",
  fieldType: FieldType,
});

onMounted(async () => {
  fetchDataAsync();
  console.log(newField.value.fieldType);
});

var props = defineProps({
  id: Number,
});

watch(
  () => props.id,
  (nv) => {
    if (nv) {
      fetchDataAsync();
    }
  },
);

async function fetchDataAsync() {
  console.log("ID: ", props.id);
  var res = await AssetTypesService.getApiV1Assettypes1(props.id!);
  assettype.value.name = res.name ? res.name : "";
  assettype.value.description = res.description ? res.description : "";
  assettype.value.fields = res.fields!;
  assettype.value.baseAssetTypes = res.baseAssetTypes!;
}

async function removeIndex(index: number) {
  await FieldsService.deleteApiV1Fields(index);
  await fetchDataAsync();
}

async function addIndex(assetTypeId: number, fieldtype: any, name: string) {
  await FieldsService.postApiV1Fields({
    assetTypeId,
    name,
    fieldtype,
  } as FieldsCreateRequest);
  await fetchDataAsync();
  newField.value = {
    name: "",
    fieldType: FieldType,
  };
}

function isEnumSet(value: any | undefined | null): boolean {
  switch (value) {
    case FieldType.NUMERIC:
    case FieldType.TEXT:
      return true;
    default:
      return false;
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
}

.field-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.input-container {
  display: table-cell;
}

.input-container > i {
  vertical-align: middle;
}
</style>
