/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldsCreateRequest } from '../models/FieldsCreateRequest';
import type { FieldsCreateResponse } from '../models/FieldsCreateResponse';
import type { FieldsDeleteResponse } from '../models/FieldsDeleteResponse';
import type { FieldsDetailsResponse } from '../models/FieldsDetailsResponse';
import type { FieldsListResponse } from '../models/FieldsListResponse';
import type { FieldsUpdateRequest } from '../models/FieldsUpdateRequest';
import type { FieldsUpdateResponse } from '../models/FieldsUpdateResponse';
import type { SetValueRequest } from '../models/SetValueRequest';
import type { SetValueResponse } from '../models/SetValueResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FieldsService {

    /**
     * @param requestBody 
     * @returns FieldsCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Fields(
requestBody?: FieldsCreateRequest,
): CancelablePromise<FieldsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/fields',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns FieldsListResponse Success
     * @throws ApiError
     */
    public static getApiV1Fields(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<FieldsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/fields',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param id 
     * @returns FieldsDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1Fields1(
id: number,
): CancelablePromise<FieldsDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/fields/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FieldsUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Fields(
id: number,
requestBody?: FieldsUpdateRequest,
): CancelablePromise<FieldsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/fields/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns FieldsDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Fields(
id: number,
): CancelablePromise<FieldsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/fields/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns SetValueResponse Success
     * @throws ApiError
     */
    public static postApiV1FieldsValues(
id: number,
requestBody?: SetValueRequest,
): CancelablePromise<SetValueResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/fields/{id}/values',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
