<template>
    <div class="w-full h-full flex flex-column">
        <Toolbar>
            <template #start>
                <div class="flex flex-row gap-3">
                    <Button icon="pi pi-user" label="Visitors" class="p-button-sm "
                        @click="(e: any) => { toggleVisitorsMenu(e); }" aria-haspopup="true" aria-controls="overlay_menu" />
                    <TieredMenu ref="visitorsMenu" id="overlay_menu" :model="visitorsMenuItems" :popup="true" />

                </div>

            </template>
        </Toolbar>
        <div class="m-5">
            <VisitorTable table-id="eccTable" ref="eccTableRef" title="ECC Dashboard" :create-visitor-action-menu="createVisitorActionMenu" :fetch-visitor-data-async="fetchVisitorDataAsync" :handle-visitor-selection-change="handleVisitorSelectionChange" ></VisitorTable>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import TieredMenu from 'primevue/tieredmenu';
import { ref, watch } from 'vue';
import { VisitorService, type ListVisitorResponse_Visitors } from '@/apis/neptune-api'
import { useToast } from 'primevue/usetoast';
import { canThrowAsyncShowToast } from '@congatec/primevue-components-lib';
import VisitorTable from './VisitorTable.vue';


//const visitorIdList = ref<number[]>([]);
const editVisitorDisabled = ref<boolean>(true);
const screenVisitorDisabled = ref<boolean>(true);
const eccTableRef = ref();//ref<null | typeof VisitorTable>(null);
const selectedVisitor = ref<Array<ListVisitorResponse_Visitors>>([]);
let selectedVisitorBackup: any[] = [];
const visitorsMenu = ref();

const toast = useToast();

const visitorsMenuItems = ref([
    {
        label: 'Screen',
        icon: 'pi pi-eye',
        disabled: screenVisitorDisabled,
        command: () => {
            screenVisitors();
        }
    },
]);



//functions area
function handleVisitorSelectionChange(selectedRows: any) {
    console.log("handleSel", selectedRows)
    selectedVisitor.value = selectedRows
    // visitorIdList.value = [];
    // selectedVisitor.value.forEach(visitor => {
    //     visitorIdList.value.push(visitor.id ? visitor.id : 0)
    // });
}

async function fetchVisitorDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {
    let res = await VisitorService.getApiV1VisitorListScreened(filters, sorts, page, rows);
    console.log("Resolution: ", res)
    return {
        totalCount: res.totalCount,
        data: res.visitors,
    }
}

function toggleVisitorsMenu(e: any) {
    visitorsMenu.value.toggle(e);
}

function createVisitorActionMenu(row: any): any {


    return [
        {
            label: 'Screen',
            icon: 'pi pi-eye',
            command: () => {
                selectedVisitorBackup = selectedVisitor.value;
                selectedVisitor.value = [];
                selectedVisitor.value.push(row);
                screenVisitors();
                selectedVisitor.value = selectedVisitorBackup;
            }
        },
    ];
}

async function screenVisitors() {
    for (let visitor of selectedVisitor.value) {
        console.log(visitor.id);
        let status = await canThrowAsyncShowToast(toast, async () => await VisitorService.putApiV1VisitorScreen(visitor.id || 0));
        if (status) {
            visitor.screeningStatus = status.status;
        }
    }
    eccTableRef.value?.forceReload();
}

//watch area
watch(() => selectedVisitor.value, (nv) => {
  console.log("Visitors:", nv.length)
  if (nv.length > 0) {
    screenVisitorDisabled.value = false;
    if (nv.length == 1) {
      editVisitorDisabled.value = false;
    } else {
      editVisitorDisabled.value = true;
    }
  } else {
    editVisitorDisabled.value = true;
    screenVisitorDisabled.value = true;
  }
})

</script>