<template>
  <FileUpload accept="text/csv" class="a-uploadprogress" :disabled="false" name="aReady[]" ref="fileUpload" customUpload
    @uploader="onFileUpload" :multiple="false" @select="onFileSelect" :maxFileSize="100000000000" @progress="showProgress"
    :class="{
      'input-invalid':
        v$.FileUploadBase64.$invalid && v$.FileUploadBase64.$error,
      'h-3rem': true,
    }">
    <template #header="{ chooseCallback, clearCallback, files }">
      <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
        <div class="flex gap-2">
          <Button @click="chooseCallback()" icon="pi pi-folder-open" rounded outlined></Button>
          <Button @click="onFileUpload($event)" icon="pi pi-cloud-upload" rounded outlined severity="success"
            :disabled="!files || files.length === 0"></Button>
          <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger"
            :disabled="!files || files.length === 0"></Button>
        </div>
        <div class="flex align-content-center align-items-center gap-3">
          <div>
            <label>Upload</label>
            <ProgressBar :value="totalSizePercent" :class="['md:w-20rem h-1rem w-full md:ml-auto']">
              {{ totalSizePercent }}%
              <!-- <span>{{ totalSize }}B / 1Mb</span> -->
            </ProgressBar>

            <label class="mt-2">Processing</label>
            <ProgressBar :value="100" :showValue="false" :mode="processingMode as any"
              :class="['md:w-20rem h-1rem w-full md:ml-auto']">
              {{ totalProcessingSizePercent }}%
              <!-- <span>{{ totalSize }}B / 1Mb</span> -->
            </ProgressBar>
          </div>
          <ProgressSpinner v-if="uploadInProgress" style="width: 40px; height: 40px" strokeWidth="8" fill="transparent"
            animationDuration=".5s" aria-label="Custom ProgressSpinner" v-tooltip.left="'Server upload in progress'" />
        </div>
      </div>
    </template>
    <template #empty>
      <p>Drag and drop files here to upload. <span>*</span></p>
    </template>
  </FileUpload>
</template>
<script setup lang="ts">
import { type Ref, ref, watch, onMounted } from "vue";
import InputText from "primevue/inputtext";
import FileUpload from "primevue/fileupload";
import { useToast } from "primevue/usetoast";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { usePrimeVue } from "primevue/config";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import { onBeforeRouteLeave } from "vue-router";
import { useEventBroker } from "@congatec/eventbroker-lib";
import ProgressSpinner from "primevue/progressspinner";

const eventBroker = useEventBroker();
eventBroker?.subscribe("/upload-progress", async (percentCompleted) => {
  totalSizePercent.value = Math.floor(percentCompleted.progress * 100);
});

const url: string = import.meta.env.VITE_SUPERBOMIO_AZURE_FUNCTION_URL; //'http://localhost:7071/api/SuperBOMIO';

const processingMode = ref("determinate");
const $primevue = usePrimeVue();
const toast = useToast();
const files: Ref<any[]> = ref([]);
const totalSize = ref(0);
const totalSizePercent = ref(0);
const totalProcessingSizePercent = ref(0);
const progress = ref(0);
const uploadInProgress = ref(false);
const fileUpload = ref();

const state = ref({
  Path: "",
  Identifier: "",
  PlmItem: "",
  FileUploadBase64: [
    { FileName: new String(null), FileContent: new String(null) },
  ],
});

//vuelidate
const rules = {
  FileUploadBase64: { required },
  Path: { required },
  Identifier: { required },
};
const v$ = useVuelidate(rules, state);

const beforeUnloadListener = (event: any) => {
  if (uploadInProgress.value) {
    event.preventDefault();
  }
};

const sleepAsync = async (ms: number = 10) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onFileUpload = async (event: any) => {
  uploadInProgress.value = true;
  state.value.FileUploadBase64 = [];
  window.addEventListener("beforeunload", beforeUnloadListener);

  try {
    processingMode.value = "indeterminate"
    console.log("fileUploadEvent: ", event);
    if (files.value) {
      const formData = new FormData();
      await Promise.all(
        files.value.map(async (file, index) => {
          formData.append('files', file);
          //await convertToBase64(file);

          let identifier = state.value.Identifier;

          if (index > 0) {
            identifier = identifier + "_" + index;
          }

          try {
            const res = await fetch(url, { method: 'POST', body: formData });
            let resBlob = await res.blob();
            //console.log("resBlob", resBlob);
            var blobUrl = URL.createObjectURL(resBlob);
            var link = document.createElement("a"); // Or maybe get it from the current document
            link.href = blobUrl;
            let filename = "OUT_" + file.name;
            link.download = filename || "default.csv";
            link.innerText = "Click here to download the file";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // const response = await axios.get(url);
            // console.log("AzureFunctionResponseAxios", response);
          } catch (exception: any) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + exception.message, life: 3000 });
            return;
          }

          //   await ArtifactService.postApiV1Artifacts(
          //     state.value.Path,
          //     "",
          //     identifier,
          //     { FileData: file } as any,
          //   );
          totalSizePercent.value = 100;
          fileUpload.value.uploadedFiles.push(file);
        }),
      );
      //console.log("uploadedData: ", formData);
      // set seleced files as uploadedFiles so that the status badge shows as completed
      fileUpload.value.files = [];
      uploadInProgress.value = false;
      toast.add({
        severity: "info",
        summary: "Success",
        detail: "File Uploaded",
        life: 3000,
      });
      window.removeEventListener("beforeunload", beforeUnloadListener);
    }
  } finally {
    processingMode.value = "determinate"
    uploadInProgress.value = false;
  }
  //callback();
};

const onFileSelect = (event: any) => {
  console.log("selectEvent: ", event);
  totalSize.value = 0;
  if (event.files) {
    files.value = event.files;
    files.value.forEach((file) => {
      totalSize.value += file.size ?? 0;
    });
  }
  totalSizePercent.value = 0;
  totalProcessingSizePercent.value = 0;

  //   imageUrl.value = event.files[0].objectURL;
  //   // set seleced files as uploadedFiles so that the status badge shows as completed
  //   imageUpload.value.uploadedFiles = imageUpload.value.files;
  //   imageUpload.value.files = [];
  //   toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
  //   convertToBase64(event.files[0]);
};

const showProgress = (event: any) => {
  totalSizePercent.value =
    (event.originalEvent.loaded * 100) / event.originalEvent.total;
  progress.value = event.originalEvent.value;
};

onBeforeRouteLeave((to, from) => {
  if (uploadInProgress.value === true) {
    const answer = window.confirm(
      "Do you really want to leave? You have unfinished uploads!",
    );
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});
</script>
<style>
/* .p-fileupload .p-progressbar {
  height: 1rem !important;
  display: none;
} */

.p-progressbar.p-progressbar-determinate .p-progressbar-label {
  display: flex;
}

.p-fileupload-content .p-progressbar {
  display: none;
}
</style>